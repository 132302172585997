import { signInWithGoogle } from "../service/firebase";
import "firebase/compat/analytics";
import "../App.css";
import { getAnalytics } from "@firebase/analytics";

const GoogleLogin = () => {
  return (
    <div className="welcome">
      <div className="topSection">
        <img
          className="companyLogo"
          src="feelbetterlogo.png"
          alt="company logo"
        />
        <h1 className="welcomeText">FeelBetter AI</h1>
        <h2 className="welcomeSlogan">
          AI Psychologist always by your side. Powered by GPT4.
        </h2>
        <button className="signinbutton" onClick={signInWithGoogle}>
          <span className="google">
            Continue with Google
          </span>
        </button>
        <p className="bottomText">
          first 1000 sign ups get free access for life
        </p>
      </div>
      <div className="demoScreen">
        <div className="demoScreenText grey">
          <div className="demoScreenTop">
            <img className="demoScreenImage" src="alex.png" alt="alex" />
          </div>
          <p>
            Alex · 27mins ago <br />
            <br />
            We used to be really close, but lately it seems like we can't have a
            conversation without it turning into a fight. Not sure what's
            changed.
          </p>
        </div>
        <div className="demoScreenText">
          <div className="demoScreenTop">
            <img className="demoScreenImage" src="clara.png" alt="clara" />
          </div>
          <p>
            Clara · 27mins ago <br /> <br />
            It sounds like there's a breakdown in communication between the two
            of you. Have there been any significant changes or events that have
            occurred recently that may have contributed to the tension?
          </p>
        </div>
        <div className="demoScreenText grey">
          <div className="demoScreenTop">
            <img className="demoScreenImage" src="alex.png" alt="alex" />
          </div>
          <p>
            Alex · 25mins ago
            <br /> <br />
            Now that you mention it, my sister got a promotion at work and she's
            been really busy with that. And I've been going through some
            personal issues.
          </p>
        </div>
        <div className="demoScreenText">
          <div className="demoScreenTop">
            <img className="demoScreenImage" src="clara.png" alt="clara" />
          </div>
          <p>
            Clara · 27mins ago <br /> <br /> It's understandable that both of
            you have been going through changes and it may have affected your
            relationship.
          </p>
        </div>
      </div>
    </div>
  );
};

//run firebase analytics
const analytics = getAnalytics();

export default GoogleLogin;
