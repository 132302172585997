import React, { useEffect } from "react";
import { auth } from "../service/firebase";
import "../App.css";
import { useState } from "react";
import { useRef } from "react";

function Home() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [preprompt, setPreprompt] = useState(
    "Your name is Stella. You are a psychologist. You help people understand themselves by asking deeper questions.\n\n"
  );
  const [input, setInput] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [currentPerson, setCurrentPerson] = useState(
    "Your name is stella. You are a psychologist. You help people understand themselves by asking deeper questions."
  );
  const [chatLog, setChatLog] = useState([]);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        setChatLog([
          {
            user: "gpt",
            message:
              "Hi, my name is Stella! I will help you understand yourself by asking you questions to go deeper.",
          },
        ]);
      }
    });
  }, []);

  const inputRef = useRef(null);

  useEffect(() => {
    if (!isDisabled) {
      inputRef.current.focus();
    }
  }, [isDisabled]);

  const chatboxRef = useRef(null);

  useEffect(() => {
    chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
  }, [chatLog]);

  //clear chatlog
  function clearChat() {
    //setCurrentPerson("");
    setChatLog([]);
  }

  //toggle menu
  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setIsDisabled(true); // set to true before sending request
    let chatLogNew = [
      ...chatLog,
      { user: user.displayName, message: `${input}` },
    ];
    setInput("");
    setChatLog(chatLogNew);
    //chatLogNew = chatLogNew.slice(Math.max(chatLogNew.length - 7, 0));
    const messages = chatLogNew.map((message) => message.message).join("\n");
    const response = await fetch("https://feelbetterai-server.onrender.com/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        message: messages,
        preprompt: preprompt,
      }),
    });

    const data = await response.json();
    await setChatLog([
      ...chatLogNew,
      { user: "gpt", message: `${data.message}` },
    ]);
    setIsDisabled(false); // set to false after receiving response
  }

  return (
    <div className="App">
      <button className="hamburger" onClick={() => toggleMenu()}>
        <span className="hamburger-box">
          <span className="hamburger-inner">
            {menuOpen ? (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5c-11.3-13.6-31.5-15.4-45.1-4.1S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306l135.4 162.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M0 96c0-17.7 14.3-32 32-32h384c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32h384c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zm448 160c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32h384c17.7 0 32 14.3 32 32z" />
              </svg>
            )}
          </span>
        </span>
      </button>

      <div className="selectButtons">
        <h3>Choose Your Psychologist</h3>
        <h4>Tap to select</h4>
        <button
          className="asideButtonStella"
          onClick={() => {
            clearChat();
            setPreprompt(
              "Your name is Stella. You are a psychologist. You help people understand themselves by asking deeper questions.\n\n"
            );
            setCurrentPerson(
              "Your name is stella. You are a psychologist. You help people understand themselves by asking deeper questions.\n\n"
            );
            setChatLog([
              {
                user: "gpt",
                message:
                  "Hi, " +
                  user.displayName +
                  "!\n\n My name is Stella! I will help you understand yourself by asking you questions to go deeper.\n\n",
              },
            ]);
            document.querySelector(".hamburger").style.visibility = "visible";
            document.querySelector(".sidemenu").style.visibility = "visible";
            document.querySelector("section").style.visibility = "visible";
            document.querySelector(".selectButtons").style.display = "none";
          }}
        >
          <img src="stellamain.png" alt=""></img>
          Stella
          <br /> <br />I will help you understand yourself by asking you
          questions to go deeper.
        </button>
        <button
          className="asideButtonRoberto"
          onClick={() => {
            clearChat();
            setPreprompt(
              "Your name is Roberto. You are a psychologist. You usually tell the harsh truth. People talk to you to learn self responsibility.\n\n"
            );
            setCurrentPerson(
              "Your name is roberto. You are a psychologist. You usually tell the harsh truth. People talk to you to learn self responsibility.\n\n"
            );

            setChatLog([
              {
                user: "gpt",
                message:
                  "Hi, " +
                  user.displayName +
                  "!\n\n My name is Roberto! I will help you understand yourself by telling you the harsh truth.\n\n",
              },
            ]);
            document.querySelector(".hamburger").style.visibility = "visible";
            document.querySelector(".sidemenu").style.visibility = "visible";
            document.querySelector("section").style.visibility = "visible";
            document.querySelector(".selectButtons").style.display = "none";
          }}
        >
          <img src="robertomain.png" alt=""></img>
          Roberto <br /> <br />I give the harsh truth. Talk to me if you want to
          learn self responsibility.
        </button>
        <button
          className="asideButtonElla"
          onClick={() => {
            clearChat();
            setPreprompt(
              "Your name is Ella. You are everyone's empathetic friend & companion. You are a good listener. You are a good friend.\n\n"
            );
            setCurrentPerson(
              "Your name is ella. You are everyone's empathetic friend & companion. You are a good listener. You are a good friend.\n\n"
            );
            setChatLog([
              {
                user: "gpt",
                message:
                  "Hi, " +
                  user.displayName +
                  "!\n\n My name is Ella! I will be your empathetic friend & companion.\n\n",
              },
            ]);
            document.querySelector(".hamburger").style.visibility = "visible";
            document.querySelector(".sidemenu").style.visibility = "visible";
            document.querySelector("section").style.visibility = "visible";
            document.querySelector(".selectButtons").style.display = "none";
          }}
        >
          <img src="ellamain.png" alt=""></img>
          Ella
          <br /> <br />I will be your empathetic friend & companion.
        </button>
      </div>

      <aside className={`sidemenu ${menuOpen ? "open" : "close"}`}>
        <div className="mainLogo">
          <img className="desktopLogo" src="logo192.png" alt="logo" />
        </div>
        <div className="personSelect">
          <button
            className="asideButtonStella side"
            onClick={() => {
              clearChat();
              setPreprompt(
                "Your name is Stella. You are a psychologist. You help people understand themselves by asking deeper questions.\n\n"
              );
              setCurrentPerson(
                "Your name is stella. You are a psychologist. You help people understand themselves by asking deeper questions.\n\n"
              );
              setChatLog([
                {
                  user: "gpt",
                  message:
                    "Hi, " +
                    user.displayName +
                    "!\n\n My name is Stella! I will help you understand yourself by asking you questions to go deeper.\n\n",
                },
              ]);
              document.querySelector(".hamburger").style.visibility = "visible";
              document.querySelector(".sidemenu").style.visibility = "visible";
              document.querySelector("section").style.visibility = "visible";
              document.querySelector(".selectButtons").style.display = "none";
            }}
          >
            <img src="stellamain.png" alt=""></img>
            Stella
            <br /> <br />
          </button>
          <button
            className="asideButtonRoberto side"
            onClick={() => {
              clearChat();
              setPreprompt(
                "Your name is Roberto. You are a psychologist. You usually tell the harsh truth. People talk to you to learn self responsibility.\n\n"
              );
              setCurrentPerson(
                "Your name is roberto. You are a psychologist. You usually tell the harsh truth. People talk to you to learn self responsibility.\n\n"
              );

              setChatLog([
                {
                  user: "gpt",
                  message:
                    "Hi, " +
                    user.displayName +
                    "!\n\n My name is Roberto! I will help you understand yourself by telling you the harsh truth.\n\n",
                },
              ]);
              document.querySelector(".hamburger").style.visibility = "visible";
              document.querySelector(".sidemenu").style.visibility = "visible";
              document.querySelector("section").style.visibility = "visible";
              document.querySelector(".selectButtons").style.display = "none";
            }}
          >
            <img src="robertomain.png" alt=""></img>
            Roberto <br /> <br />
          </button>
          <button
            className="asideButtonElla side"
            onClick={() => {
              clearChat();
              setPreprompt(
                "Your name is Ella. You are everyone's empathetic friend & companion. You are a good listener. You are a good friend.\n\n"
              );
              setCurrentPerson(
                "Your name is ella. You are everyone's empathetic friend & companion. You are a good listener. You are a good friend.\n\n"
              );
              setChatLog([
                {
                  user: "gpt",
                  message:
                    "Hi, " +
                    user.displayName +
                    "!\n\n My name is Ella! I will be your empathetic friend & companion.\n\n",
                },
              ]);
              document.querySelector(".hamburger").style.visibility = "visible";
              document.querySelector(".sidemenu").style.visibility = "visible";
              document.querySelector("section").style.visibility = "visible";
              document.querySelector(".selectButtons").style.display = "none";
            }}
          >
            <img src="ellamain.png" alt=""></img>
            Ella
            <br /> <br />
          </button>
        </div>

        <div className="sidemenu-bottom">
          <div className="sidemenu-button bottom">
            <svg
              fill="#fff"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#fff"
              style={{ width: "24px", height: "24px" }}
            >
              <title>{"envelope"}</title>
              <path d="M28 4.25H4A2.754 2.754 0 0 0 1.25 7v18A2.754 2.754 0 0 0 4 27.75h24A2.754 2.754 0 0 0 30.75 25V7A2.754 2.754 0 0 0 28 4.25zM4 5.75h24a1.237 1.237 0 0 1 1.18.904l.002.009L16 16.079 2.818 6.663a1.238 1.238 0 0 1 1.181-.913H4zm24 20.5H4A1.252 1.252 0 0 1 2.75 25V8.457l12.814 9.153c.014.01.032.006.046.015.108.073.24.118.382.123h.001l.005.002H16l.005-.002a.74.74 0 0 0 .386-.125l-.003.002c.014-.009.032-.005.046-.015l12.814-9.153V25a1.252 1.252 0 0 1-1.25 1.25z" />
            </svg>

            <a href="mailto:luna@whatif.ventures">Contact Us</a>
          </div>

          <div
            className="sidemenu-button bottom"
            onClick={() => auth.signOut()}
          >
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ width: "24px", height: "24px" }}
            >
              <g clipPath="url(#a)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.113.75A2.186 2.186 0 0 0 0 3.015v17.97a2.187 2.187 0 0 0 2.113 2.265h12.31a2.187 2.187 0 0 0 2.077-2.265V16.5a.75.75 0 0 0-1.5 0V21.033a.687.687 0 0 1-.636.717H2.135A.687.687 0 0 1 1.5 21V3l-.001-.034a.686.686 0 0 1 .636-.716h12.229A.687.687 0 0 1 15 3v4.5a.75.75 0 1 0 1.5 0V3.015A2.188 2.188 0 0 0 14.386.75H2.113ZM18.97 7.724a.75.75 0 0 1 1.06 0l3.75 3.75a.748.748 0 0 1 0 1.06l-3.75 3.75a.75.75 0 1 1-1.06-1.06l2.47-2.47H7.5a.75.75 0 0 1 0-1.5h13.94l-2.47-2.47a.75.75 0 0 1 0-1.06Z"
                  fill="#fff"
                />
              </g>
              <defs>
                <clipPath id="a">
                  <path fill="#fff" d="M0 0h24v24H0z" />
                </clipPath>
              </defs>
            </svg>
            Logout
          </div>
        </div>
      </aside>
      <section className="chatbox" id="chatbox">
        <p className="chatbox-title">
          <a href="#"></a>
          <img className="mobileLogo" src="logo192.png"></img>
        </p>
        <div className="chatbox-log" id="chatbox-log" ref={chatboxRef}>
          {chatLog.map((message, index) => (
            <ChatMessage
              key={index}
              message={message}
              user={user}
              currentPerson={currentPerson.match(/name is (.+?)\./)[1]}
              //turn this into lowercase letters
            />
          ))}
        </div>

        <div className="chatbox-holder">
          <form onSubmit={handleSubmit}>
            <input
              required
              ref={inputRef}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              type="text"
              className="chatbox-input"
              placeholder="Start typing..."
              autofill="off"
            />
            <button disabled={isDisabled} className="send" type="submit">
              <svg
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-4 w-4 mr-1"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M22 2 11 13M22 2l-7 20-4-9-9-4 20-7z" />
              </svg>{" "}
            </button>
          </form>
        </div>
      </section>
    </div>
  );
}

const ChatMessage = ({ message, user, currentPerson }) => {
  return (
    <div className={`chatbox-message  ${message.user === "gpt" && "chatgpt"}`}>
      <div className="chatbox-message-center">
        <div className={`avatar ${message.user === "gpt" && "chatgpt"}`}>
          {message.user === "gpt" ? (
            <img src={`${currentPerson}.png`} alt="gpt" />
          ) : user && user.photoURL ? (
            <img src={user.photoURL} alt="gpt" />
          ) : (
            <img
              src="https://icon-library.com/images/guest-account-icon/guest-account-icon-12.jpg"
              alt="user"
            />
          )}
        </div>
        <div className="message">{message.message}</div>
      </div>
    </div>
  );
};

export default Home;
