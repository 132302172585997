import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAWiqIBC4fL7tir8BXXJ8IKetHy6bjTWK8",
  authDomain: "feelbetterai.firebaseapp.com",
  projectId: "feelbetterai",
  storageBucket: "feelbetterai.appspot.com",
  messagingSenderId: "1029463740027",
  appId: "1:1029463740027:web:bbff3698cdf40b4df11cde",
  measurementId: "G-G9KTCEYRLF",
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();

//initialize firebase analytics

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });
firebase.analytics();

export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase;
